/* tslint:disable */
/* eslint-disable */
/**
 * card
 * card API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Card } from '../models';
// @ts-ignore
import type { CardMini } from '../models';
// @ts-ignore
import type { PageCard } from '../models';
// @ts-ignore
import type { Pageable } from '../models';
/**
 * CardFinderControllerApi - axios parameter creator
 * @export
 */
export const CardFinderControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 🫵🏼 All your active cards
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllActiveOrGuestGroups: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} [ownerId] 
         * @param {boolean} [finished] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllCardsPageable: async (pageable: Pageable, ownerId?: number, finished?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('findAllCardsPageable', 'pageable', pageable)
            const localVarPath = `/pageable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }

            if (finished !== undefined) {
                localVarQueryParameter['finished'] = finished;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cardId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCardById: async (cardId: number, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('findCardById', 'cardId', cardId)
            const localVarPath = `/one/{cardId}`
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [ownerId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNextCards: async (ownerId?: number, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/next`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CardFinderControllerApi - functional programming interface
 * @export
 */
export const CardFinderControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CardFinderControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 🫵🏼 All your active cards
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllActiveOrGuestGroups(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CardMini>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllActiveOrGuestGroups(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CardFinderControllerApi.findAllActiveOrGuestGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} [ownerId] 
         * @param {boolean} [finished] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllCardsPageable(pageable: Pageable, ownerId?: number, finished?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllCardsPageable(pageable, ownerId, finished, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CardFinderControllerApi.findAllCardsPageable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} cardId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCardById(cardId: number, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Card>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCardById(cardId, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CardFinderControllerApi.findCardById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [ownerId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findNextCards(ownerId?: number, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Card>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findNextCards(ownerId, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CardFinderControllerApi.findNextCards']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CardFinderControllerApi - factory interface
 * @export
 */
export const CardFinderControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CardFinderControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 🫵🏼 All your active cards
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllActiveOrGuestGroups(limit?: number, offset?: number, options?: any): AxiosPromise<Array<CardMini>> {
            return localVarFp.findAllActiveOrGuestGroups(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} [ownerId] 
         * @param {boolean} [finished] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllCardsPageable(pageable: Pageable, ownerId?: number, finished?: boolean, options?: any): AxiosPromise<PageCard> {
            return localVarFp.findAllCardsPageable(pageable, ownerId, finished, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cardId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCardById(cardId: number, authorization?: string, options?: any): AxiosPromise<Card> {
            return localVarFp.findCardById(cardId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [ownerId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNextCards(ownerId?: number, limit?: number, offset?: number, options?: any): AxiosPromise<Array<Card>> {
            return localVarFp.findNextCards(ownerId, limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CardFinderControllerApi - object-oriented interface
 * @export
 * @class CardFinderControllerApi
 * @extends {BaseAPI}
 */
export class CardFinderControllerApi extends BaseAPI {
    /**
     * 
     * @summary 🫵🏼 All your active cards
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardFinderControllerApi
     */
    public findAllActiveOrGuestGroups(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return CardFinderControllerApiFp(this.configuration).findAllActiveOrGuestGroups(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} [ownerId] 
     * @param {boolean} [finished] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardFinderControllerApi
     */
    public findAllCardsPageable(pageable: Pageable, ownerId?: number, finished?: boolean, options?: RawAxiosRequestConfig) {
        return CardFinderControllerApiFp(this.configuration).findAllCardsPageable(pageable, ownerId, finished, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cardId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardFinderControllerApi
     */
    public findCardById(cardId: number, authorization?: string, options?: RawAxiosRequestConfig) {
        return CardFinderControllerApiFp(this.configuration).findCardById(cardId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [ownerId] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardFinderControllerApi
     */
    public findNextCards(ownerId?: number, limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return CardFinderControllerApiFp(this.configuration).findNextCards(ownerId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}

